import { $themeBreakpoints } from '@themeConfig'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
  },
  actions: {
    listContentItems(ctx, { contentType, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/${contentType}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getContentItem(ctx, { contentType, id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/${contentType}/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addContentItem(ctx, { contentType, contentData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/${contentType}`, contentData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateContentItem(ctx, { contentType, id, contentItemData }) {
      console.log(contentItemData);
      return new Promise((resolve, reject) => {
        axios
          .put(`/${contentType}/${id}`, contentItemData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteContentItem(ctx, { contentType, id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/${contentType}/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addReportFile(ctx, { id, file }) {
      const formData = new FormData()
      formData.append('file', file)
      const headers = { 'Content-Type': 'multipart/form-data' }
      return new Promise((resolve, reject) => {
        axios
          .post(`/reports/${id}/file`, formData, { headers })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteReportFile(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/reports/${id}/file`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getReportFile(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/reports/${id}/file`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFileFile(ctx, { id, file }) {
      const formData = new FormData()
      formData.append('file', file)
      const headers = { 'Content-Type': 'multipart/form-data' }
      return new Promise((resolve, reject) => {
        axios
          .post(`/files/${id}/file`, formData, { headers })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteFileFile(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/files/${id}/file`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getFileFile(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/files/${id}/file`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addGalleryPhoto(ctx, { id, file }) {
      const formData = new FormData()
      formData.append('file', file)
      const headers = { 'Content-Type': 'multipart/form-data' }
      return new Promise((resolve, reject) => {
        axios
          .post(`/galleries/${id}/photos`, formData, { headers })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    listWpItems(ctx, { contentType, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/${contentType}`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getWpItem(ctx, { contentType, id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/${contentType}/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrgs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/orgs', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrg(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/orgs/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrgMembers(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/orgs/${id}/members`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addOrg(ctx, orgData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/orgs', orgData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateOrg(ctx, { id, orgData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/orgs/${id}`, orgData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteOrg(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/orgs/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPermissions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/permissions', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPermission(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/permissions/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPermission(ctx, permissionData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/permissions', permissionData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePermission(ctx, { id, permissionData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/permissions/${id}`, permissionData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPermissionOrgs(ctx, { id, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/permissions/${id}/orgs`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    permissionAddOrg(ctx, { id, orgId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/permissions/${id}/orgs`, { id: orgId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    permissionRemoveOrg(ctx, { id, orgId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/permissions/${id}/orgs/${orgId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePermission(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/permissions/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAdvisers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/advisers', { params: { perPage: 1000, ...queryParams } })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/users', userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateUser(ctx, { id, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/users/${id}`, userData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTopics(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/topics', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTopic(ctx, topicData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/topics', topicData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTopic(ctx, { id, topicData }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/topics/${id}`, topicData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteTopic(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/topics/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategories(ctx, { contentType, queryParams }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${contentType}/categories`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateVideoOrder(ctx, { uuid, videoOrder }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/playlists/${uuid}/videos`, { videoOrder })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteVideo(ctx, { uuid, videoId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/playlists/${uuid}/videos/${videoId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getVideoUploadToken(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
          axios
            .get(`/playlists/${uuid}/uploadToken`)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
    updatePhotoOrder(ctx, { uuid, photoOrder }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/galleries/${uuid}/photos`, { photoOrder })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePhoto(ctx, { uuid, photoId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/galleries/${uuid}/photos/${photoId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePhoto(ctx, { uuid, photoId, alt_tag }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/galleries/${uuid}/photos/${photoId}`, { alt_tag })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createAgreement(ctx, { content }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/agreements', { content })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAgreements(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get('/agreements')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
