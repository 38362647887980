// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

// APP CONFIG
export const $themeConfig = {
  app: {
    appName: 'DWG Admin', // Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require
    appLogoImage: require('@/assets/images/logo/logo.png'), // Will update logo in navigation menu (Branding)
    apiUrl: 'https://api.digitalworkplacegroup.com/v1',   // DO NOT CHANGE this value in the .example file, it's used as a placeholder for the deploy scripts
    membersUrl: 'https://members.digitalworkplacegroup.com',  // DO NOT CHANGE this value in the .example file, it's used as a placeholder for the deploy scripts
    oryUrl: 'https://passport.digitalworkplacegroup.com', // DO NOT CHANGE this value in the .example file, it's used as a placeholder for the deploy scripts
    allowShowInFeed: [
      'App\\Models\\Report',
      'App\\Models\\Gallery',
      'App\\Models\\Playlist',
      'Wordpress\\ask-dwg',
      'Wordpress\\good-practice',
    ],
  },
  layout: {
    isRTL: false,
    skin: 'semi-dark', // light, dark, bordered, semi-dark
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: '', // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static', // static, sticky, hidden
    },
    customizer: true,
    enableScrollToTop: true,
  },
  membersApp: {
    routes: {
      reports: 'research-library',
      playlists: 'videos',
      galleries: 'galleries',
      events: 'events',
      files: 'files',
      'ask-dwg-articles': 'ask-dwg',
      'kb-articles': 'case-study',
      'news-articles': 'news',
    },
  },
}
