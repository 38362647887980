import Vue from 'vue'
import VueRouter from 'vue-router'
import { isUserLoggedIn, getUserData } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
        managerAccessible: true,
      },
    },
    {
      path: '/organisations',
      name: 'orgs',
      component: () => import('@/views/org/orgs-list/OrgsList.vue'),
      meta: {
        pageTitle: 'Organisations',
        breadcrumb: [
          {
            text: 'Organisations',
            active: true,
          },
        ],
      },
    },
    {
      path: '/organisations/view/:id',
      name: 'orgs-view',
      component: () => import('@/views/org/orgs-view/OrgsView.vue'),
      meta: {
        pageTitle: 'Organisation',
        breadcrumb: [
          {
            text: 'Organisations',
            to: { name: 'orgs' },
          },
          {
            text: 'View Organisation',
            active: true,
          },
        ],
      },
    },
    {
      path: '/organisations/edit/:id',
      name: 'orgs-edit',
      component: () => import('@/views/org/orgs-edit/OrgsEdit.vue'),
      meta: {
        pageTitle: 'Edit Organisation',
        breadcrumb: [
          {
            text: 'Organisations',
            to: { name: 'orgs' },
          },
          {
            text: 'Organisation',
            to: { name: 'orgs-view' },
          },
          {
            text: 'Edit Organisation',
            active: true,
          },
        ],
      },
    },
    {
      path: '/my-organisation',
      name: 'myorg-view',
      component: () => import('@/views/my-org/orgs-view/OrgsView.vue'),
      meta: {
        pageTitle: 'My Organisation',
        breadcrumb: [
          {
            text: 'My Organisation',
            active: true,
          },
        ],
        managerAccessible: true,
      },
    },
    {
      path: '/my-organisation/edit',
      name: 'myorg-edit',
      component: () => import('@/views/my-org/orgs-edit/OrgsEdit.vue'),
      meta: {
        pageTitle: 'My Organisation',
        breadcrumb: [
          {
            text: 'My Organisation',
            to: { name: 'myorg-view' },
          },
          {
            text: 'Edit Organisation',
            active: true,
          },
        ],
        managerAccessible: true,
      },
    },
    {
      path: '/my-organisation/users/:id/edit',
      name: 'myorg-user-edit',
      component: () => import('@/views/my-org/users-edit/UsersEdit.vue'),
      meta: {
        pageTitle: 'My Organisation',
        breadcrumb: [
          {
            text: 'My Organisation',
            to: { name: 'myorg-view' },
          },
          {
            text: 'Edit User',
            active: true,
          },
        ],
        managerAccessible: true,
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/user/users-list/UsersList.vue'),
      meta: {
        pageTitle: 'Users',
        breadcrumb: [
          {
            text: 'Users',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/view/:id',
      name: 'users-view',
      component: () => import('@/views/user/users-view/UsersView.vue'),
      meta: {
        pageTitle: 'User',
        breadcrumb: [
          {
            text: 'Users',
            to: { name: 'users' },
          },
          {
            text: 'View User',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/edit/:id',
      name: 'users-edit',
      component: () => import('@/views/user/users-edit/UsersEdit.vue'),
      meta: {
        pageTitle: 'Edit User',
        breadcrumb: [
          {
            text: 'Users',
            to: { name: 'users' },
          },
          {
            text: 'User',
            to: { name: 'users-view' },
          },
          {
            text: 'Edit User',
            active: true,
          },
        ],
      },
    },
    {
      path: '/permissions',
      name: 'permissions',
      component: () => import('@/views/permission/permissions-list/PermissionsList.vue'),
      meta: {
        pageTitle: 'Permissions',
        breadcrumb: [
          {
            text: 'Permissions',
            active: true,
          },
        ],
      },
    },
    {
      path: '/permissions/view/:id',
      name: 'permissions-view',
      component: () => import('@/views/permission/permissions-view/PermissionsView.vue'),
      meta: {
        pageTitle: 'Permission',
        breadcrumb: [
          {
            text: 'Permissions',
            to: { name: 'permissions' },
          },
          {
            text: 'View Permission',
            active: true,
          },
        ],
      },
    },
    {
      path: '/permissions/edit/:id',
      name: 'permissions-edit',
      component: () => import('@/views/permission/permissions-edit/PermissionsEdit.vue'),
      meta: {
        pageTitle: 'Edit Permission',
        breadcrumb: [
          {
            text: 'Permissions',
            to: { name: 'users' },
          },
          {
            text: 'Permission',
            to: { name: 'permissions-view' },
          },
          {
            text: 'Edit Permission',
            active: true,
          },
        ],
      },
    },
    {
      path: '/topics',
      name: 'topics',
      component: () => import('@/views/topic/TopicsList.vue'),
      meta: {
        pageTitle: 'Topics',
        breadcrumb: [
          {
            text: 'Topics',
            active: true,
          },
        ],
      },
    },
    {
      path: '/reports',
      name: 'reports',
      component: () => import('@/views/report/reports-list/ReportsList.vue'),
      meta: {
        pageTitle: 'Reports',
        breadcrumb: [
          {
            text: 'Reports',
            active: true,
          },
        ],
      },
    },
    {
      path: '/reports/edit/:id',
      name: 'reports-edit',
      component: () => import('@/views/report/reports-edit/ReportsEdit.vue'),
      meta: {
        pageTitle: 'Edit Report',
        breadcrumb: [
          {
            text: 'Reports',
            to: { name: 'reports' },
          },
          {
            text: 'Report',
            to: { name: 'reports-view' },
          },
          {
            text: 'Edit Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/reports/view/:id',
      name: 'reports-view',
      component: () => import('@/views/report/reports-view/ReportsView.vue'),
      meta: {
        pageTitle: 'View Report',
        breadcrumb: [
          {
            text: 'Reports',
            to: { name: 'reports' },
          },
          {
            text: 'View Report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import('@/views/terms/Terms.vue'),
      meta: {
        pageTitle: 'Terms',
        breadcrumb: [
          {
            text: 'Terms',
            active: true,
          },
        ],
      },
    },
    {
      path: '/files',
      name: 'files',
      component: () => import('@/views/content-file/content-files-list/ContentFilesList.vue'),
      meta: {
        pageTitle: 'Files',
        breadcrumb: [
          {
            text: 'Files',
            active: true,
          },
        ],
      },
    },
    {
      path: '/files/edit/:id',
      name: 'files-edit',
      component: () => import('@/views/content-file/content-files-edit/ContentFilesEdit.vue'),
      meta: {
        pageTitle: 'Edit File',
        breadcrumb: [
          {
            text: 'Files',
            to: { name: 'files' },
          },
          {
            text: 'File',
            to: { name: 'files-view' },
          },
          {
            text: 'Edit File',
            active: true,
          },
        ],
      },
    },
    {
      path: '/files/view/:id',
      name: 'files-view',
      component: () => import('@/views/content-file/content-files-view/ContentFilesView.vue'),
      meta: {
        pageTitle: 'View File',
        breadcrumb: [
          {
            text: 'Files',
            to: { name: 'files' },
          },
          {
            text: 'View File',
            active: true,
          },
        ],
      },
    },
    {
      path: '/events',
      name: 'events',
      component: () => import('@/views/event/events-list/EventsList.vue'),
      meta: {
        pageTitle: 'Events',
        breadcrumb: [
          {
            text: 'Events',
            active: true,
          },
        ],
      },
    },
    {
      path: '/events/view/:id',
      name: 'events-view',
      component: () => import('@/views/event/events-view/EventsView.vue'),
      meta: {
        pageTitle: 'View Event',
        breadcrumb: [
          {
            text: 'Events',
            to: { name: 'events' },
          },
          {
            text: 'View Event',
            active: true,
          },
        ],
      },
    },
    {
      path: '/events/edit/:id',
      name: 'events-edit',
      component: () => import('@/views/event/events-edit/EventsEdit.vue'),
      meta: {
        pageTitle: 'Edit Event',
        breadcrumb: [
          {
            text: 'Events',
            to: { name: 'events' },
          },
          {
            text: 'Event',
            to: { name: 'events-view' },
          },
          {
            text: 'Edit Event',
            active: true,
          },
        ],
      },
    },
    {
      path: '/galleries',
      name: 'galleries',
      component: () => import('@/views/gallery/galleries-list/GalleriesList.vue'),
      meta: {
        pageTitle: 'Galleries',
        breadcrumb: [
          {
            text: 'Galleries',
            active: true,
          },
        ],
      },
    },
    {
      path: '/galleries/view/:id',
      name: 'galleries-view',
      component: () => import('@/views/gallery/galleries-view/GalleriesView.vue'),
      meta: {
        pageTitle: 'View Gallery',
        breadcrumb: [
          {
            text: 'Galleries',
            to: { name: 'galleries' },
          },
          {
            text: 'View Gallery',
            active: true,
          },
        ],
      },
    },
    {
      path: '/galleries/edit/:id',
      name: 'galleries-edit',
      component: () => import('@/views/gallery/galleries-edit/GalleriesEdit.vue'),
      meta: {
        pageTitle: 'Edit Gallery',
        breadcrumb: [
          {
            text: 'Galleries',
            to: { name: 'galleries' },
          },
          {
            text: 'Gallery',
            to: { name: 'galleries-view' },
          },
          {
            text: 'Edit Gallery',
            active: true,
          },
        ],
      },
    },
    {
      path: '/playlists',
      name: 'playlists',
      component: () => import('@/views/playlist/playlists-list/PlaylistsList.vue'),
      meta: {
        pageTitle: 'Playlists',
        breadcrumb: [
          {
            text: 'Playlists',
            active: true,
          },
        ],
      },
    },
    {
      path: '/playlists/view/:id',
      name: 'playlists-view',
      component: () => import('@/views/playlist/playlists-view/PlaylistsView.vue'),
      meta: {
        pageTitle: 'View Playlist',
        breadcrumb: [
          {
            text: 'Playlists',
            to: { name: 'playlists' },
          },
          {
            text: 'View Playlist',
            active: true,
          },
        ],
      },
    },
    {
      path: '/playlists/edit/:id',
      name: 'playlists-edit',
      component: () => import('@/views/playlist/playlists-edit/PlaylistsEdit.vue'),
      meta: {
        pageTitle: 'Edit Playlist',
        breadcrumb: [
          {
            text: 'Playlists',
            to: { name: 'playlists' },
          },
          {
            text: 'Playlist',
            to: { name: 'playlists-view' },
          },
          {
            text: 'Edit Playlist',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pages',
      name: 'pages',
      component: () => import('@/views/page/pages-list/PagesList.vue'),
      meta: {
        pageTitle: 'Pages',
        breadcrumb: [
          {
            text: 'Pages',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pages/view/:id',
      name: 'pages-view',
      component: () => import('@/views/page/pages-view/PagesView.vue'),
      meta: {
        pageTitle: 'View Page',
        breadcrumb: [
          {
            text: 'Pages',
            to: { name: 'pages' },
          },
          {
            text: 'View Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pages/edit/:id',
      name: 'pages-edit',
      component: () => import('@/views/page/pages-edit/PagesEdit.vue'),
      meta: {
        pageTitle: 'Edit Page',
        breadcrumb: [
          {
            text: 'Pages',
            to: { name: 'pages' },
          },
          {
            text: 'Page',
            to: { name: 'pages-view' },
          },
          {
            text: 'Edit Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news',
      name: 'news-articles',
      component: () => import('@/views/news/news-list/NewsList.vue'),
      meta: {
        pageTitle: 'News',
        breadcrumb: [
          {
            text: 'News',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news/view/:id',
      name: 'news-articles-view',
      component: () => import('@/views/news/news-view/NewsView.vue'),
      meta: {
        pageTitle: 'View Article',
        breadcrumb: [
          {
            text: 'News Articles',
            to: { name: 'news-articles' },
          },
          {
            text: 'View Article',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news/edit/:id',
      name: 'news-articles-edit',
      component: () => import('@/views/news/news-edit/NewsEdit.vue'),
      meta: {
        pageTitle: 'Edit Article',
        breadcrumb: [
          {
            text: 'News Articles',
            to: { name: 'news-articles' },
          },
          {
            text: 'Article',
            to: { name: 'news-articles-view' },
          },
          {
            text: 'Edit Article',
            active: true,
          },
        ],
      },
    },
    {
      path: '/knowledge-base',
      name: 'kb-articles',
      component: () => import('@/views/knowledge-base/knowledge-base-list/KnowledgeBaseList.vue'),
      meta: {
        pageTitle: 'Knowledge Base',
        breadcrumb: [
          {
            text: 'Knowledge Base',
            active: true,
          },
        ],
      },
    },
    {
      path: '/knowledge-base/view/:id',
      name: 'kb-articles-view',
      component: () => import('@/views/knowledge-base/knowledge-base-view/KnowledgeBaseView.vue'),
      meta: {
        pageTitle: 'View Article',
        breadcrumb: [
          {
            text: 'Knowledge Base Articles',
            to: { name: 'kb-articles' },
          },
          {
            text: 'View KB Article',
            active: true,
          },
        ],
      },
    },
    {
      path: '/knowledge-base/edit/:id',
      name: 'kb-articles-edit',
      component: () => import('@/views/knowledge-base/knowledge-base-edit/KnowledgeBaseEdit.vue'),
      meta: {
        pageTitle: 'Edit Article',
        breadcrumb: [
          {
            text: 'Knowledge Base Articles',
            to: { name: 'kb-articles' },
          },
          {
            text: 'Article',
            to: { name: 'kb-articles-view' },
          },
          {
            text: 'Edit Article',
            active: true,
          },
        ],
      },
    },
    {
      path: '/research-blog',
      name: 'research-articles',
      component: () => import('@/views/research/research-list/ResearchList.vue'),
      meta: {
        pageTitle: 'Research Blog',
        breadcrumb: [
          {
            text: 'Research Blog',
            active: true,
          },
        ],
      },
    },
    {
      path: '/research-blog/view/:id',
      name: 'research-articles-view',
      component: () => import('@/views/research/research-view/ResearchView.vue'),
      meta: {
        pageTitle: 'View Article',
        breadcrumb: [
          {
            text: 'Research Articles',
            to: { name: 'research-articles' },
          },
          {
            text: 'View Research Article',
            active: true,
          },
        ],
      },
    },
    {
      path: '/research-blog/edit/:id',
      name: 'research-articles-edit',
      component: () => import('@/views/research/research-edit/ResearchEdit.vue'),
      meta: {
        pageTitle: 'Edit Article',
        breadcrumb: [
          {
            text: 'Research Articles',
            to: { name: 'research-articles' },
          },
          {
            text: 'Article',
            to: { name: 'research-articles-view' },
          },
          {
            text: 'Edit Article',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ask-dwg',
      name: 'ask-dwg-articles',
      component: () => import('@/views/ask-dwg/ask-dwg-list/AskDwgList.vue'),
      meta: {
        pageTitle: 'Ask DWG',
        breadcrumb: [
          {
            text: 'Ask DWG',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ask-dwg/view/:id',
      name: 'ask-dwg-articles-view',
      component: () => import('@/views/ask-dwg/ask-dwg-view/AskDwgView.vue'),
      meta: {
        pageTitle: 'View Article',
        breadcrumb: [
          {
            text: 'Ask DWG Articles',
            to: { name: 'ask-dwg-articles' },
          },
          {
            text: 'View Article',
            active: true,
          },
        ],
      },
    },
    {
      path: '/ask-dwg/edit/:id',
      name: 'ask-dwg-articles-edit',
      component: () => import('@/views/ask-dwg/ask-dwg-edit/AskDwgEdit.vue'),
      meta: {
        pageTitle: 'Edit Article',
        breadcrumb: [
          {
            text: 'Ask DWG Articles',
            to: { name: 'ask-dwg-articles' },
          },
          {
            text: 'Article',
            to: { name: 'ask-dwg-articles-view' },
          },
          {
            text: 'Edit Article',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        authNotRequired: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
        authNotRequired: true,
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {

  // Check if auth is required on this route
  // (including nested routes).
  const authNotRequired = routeTo.matched.some(route => route.meta.authNotRequired)

  // If auth isn't required for the route, just continue.
  if (authNotRequired) return next()

  if (isUserLoggedIn()) {
    const userData = getUserData();
    if (userData.is_admin || (userData.is_manager && routeTo.meta.managerAccessible)) {
      next()
    } else {
      next({ name: 'error-404' })
    }
  } else {
    next({ name: 'login', query: { redirect: routeTo.fullPath } })
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
